export const colors = {
  primaryColor: '#009e9a',
  secondaryColor: '#00C9BD',
  tertiaryColor: '#d9915e',
  destructiveColor: '#9e0003',
  leaderboardColor: '#c9006b',
  planColor: '#08689C',
  membershipColor: '#44459A',
  linkColor: '#2980b9',
  textColorDark: 'rgba(0,0,0, 0.87)',
  textColor: 'rgba(0,0,0, 0.54)',
  overlayBg: 'rgba(0,0,0, 0.2)',
  headerColor: 'rgba(255,255,255, 0.54)',
  dividerColor: '#ccc',
  white: '#ffffff',
  black: '#000000',
  background: '#ccc',
  lightGray: '#f9f9f9f9',
  darkBackground: '#2b292a',
};

export function isMobile() {
  const userAgent = navigator.userAgent;

  if (/android|iPad|iPhone/i.test(userAgent)) {
    return true;
  }

  return false;
}
